<template>
  <input
      v-model="amount"
      type="number"
      class="form-control amount"
      @keypress="allowOnlyNumber"
  >
</template>

<script>

export default {
  name: 'AmountInput',
  props: {
    value: {
      type: String
    },
    maxlength: {
      type: Number,
      default: 13
    }
  },
  computed: {
    amount: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    allowOnlyNumber: function (event) {
      let pattern = /\d|[.,]/
      if (!pattern.test(event.key) || this.amount?.length > this.maxlength) {
        event.preventDefault()
      }
    },
  }
}
</script>
