<template>
  <div class="attach-select" :class="{'file-uploaded': isFileUploaded}">
    <multiselect
      v-model="type"
      :options="options"
      label="label"
      :class="{'valid': isTypeFieldValid}"
      :allow-empty="false"
      :show-labels="false"
    />

    <div class="custom-attach-input">
      <label class="custom-attach-label">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M18.5 16H7c-2.21 0-4-1.79-4-4s1.79-4 4-4h12.5c1.38 0 2.5 1.12 2.5 2.5S20.88 13 19.5 13H9c-.55 0-1-.45-1-1s.45-1 1-1h9.5V9.5H9c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h10.5c2.21 0 4-1.79 4-4s-1.79-4-4-4H7c-3.04 0-5.5 2.46-5.5 5.5s2.46 5.5 5.5 5.5h11.5V16z" />
        </svg>
        <input type="file" @change="changeFileInput" ref="file" :accept="acceptExtensionsList" class="valid">
      </label>
    </div>

    <p class="custom-attach-uploaded" v-if="isFileUploaded || progress">
      <span class="progress" v-if="progress">
        <span class="progress-line" :style="{width: progress + '%'}"></span>
      </span>
      <template v-else-if="isFileUploaded">
        <span class="custom-attach-uploaded-text">
          <a :href="data.url" target="_blank">{{ data.original_name }}</a>
        </span>
        <span class="remove-uploaded" @click="resetFile">&nbsp;x</span>
      </template>
    </p>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'FileUploading',
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: Object,
      default: () => {
        return {
          type: [],
          data: []
        }
      }
    },
    acceptExtensions: {
      type: Array,
      default: () => ['.pdf', '.jpg', '.jpeg', '.png']
    }
  },
  data () {
    return {
      type: [],
      data: [],
      busy: false,
      progress: undefined
    }
  },
  computed: {
    /**
     * Make Valid class for select
     */
    isTypeFieldValid: function () {
      return Object.keys(this.type).length > 0
    },

    /**
     * Check is file already uploaded
     */
    isFileUploaded: function () {
      return Object.keys(this.data).length > 0 && !this.data.error
    },

    val: function () {
      return {
        type: this.type,
        data: this.data
      }
    },

    acceptExtensionsList () {
      return this.acceptExtensions.join(', ')
    },
  },
  watch: {
    'type' () {
      this.$emit('input', this.val)
      this.$emit('change:multiselect')
    },
    'data' () {
      this.$emit('input', this.val)
      this.$emit('change:file')
    },
    'busy' () {
      this.$emit('change:busy', this.busy)
    }
  },
  methods: {
    /**
     * Reset file field
     */
    resetFile: function () {
      this.data = []
    },

    /**
     * Upload file to server and save data from backend
     */
    changeFileInput: function (event) {
      this.busy = true
      this.progress = undefined

      let files = event.target.files
      if (!files.length) {
        this.busy = false
        return
      }

      const data = new FormData()
      data.append('file', files[0])

      let config = {
        onUploadProgress: (progressEvent) => {
          let val = Math.round( (progressEvent.loaded * 100) / progressEvent.total)
          this.progress = val

          if (val === 100) {
            this.progress = undefined
          }
        }
      }

      this.$sdk.payment.upload(data, config).then(({ data }) => {
        this.data = data
      }).finally(() => {
        this.busy = false
      })

      this.$refs['file'].value = ''
    }
  }
}
</script>

<style lang="less">
.attach-select {
  position: relative;

  &.file-uploaded
  {
    margin-bottom: 10px;

    .remove-uploaded
    {
      display:     block;
      margin-left: 7px;
      cursor:      pointer;
    }
  }


  .custom-attach-input {
    input {
      display: none;
    }

    .custom-attach-label {
      cursor:   pointer;
      position: absolute;
      right:    50px;
      top:      0;
      height:   36px;

      svg
      {
        fill: #FFF;
        position: relative;
        width:    20px;
        height:   20px;
        top:      50%;
        transform: translateY(-50%);
      }
    }
  }

  .custom-attach-uploaded {
    margin:  9px 20px;
    font-size:    12px;
    font-weight:  500;
    max-width:    100%;
    display: flex;
    flex-direction: row;

    &-text {
      max-width:     100%;
      text-overflow: ellipsis;
      white-space:   nowrap;
      overflow:      hidden;
    }
  }

  .remove-uploaded {
    display: none;
  }

  .progress {
    border: 1px solid #8e8e8e;
    width: 100%;
    height: 14px;
    display: block;
    margin-right: 20px;
    max-width: 120px;
    position: relative;

    &-line {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: rgba(153, 153, 153, 0.5);
      transition: all .2s linear;
    }
  }
}
</style>
