<template>
  <div class="modal basic" v-body-scroll-lock="true">
    <div class="container">
      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <h2 class="modal-title">
        Payment details
      </h2>

      <div class="modal-forms-wrapper">
        <div class="fixed-navigation">
          <ol class="fixed-navigation-list">
            <li class="fixed-navigation-item" :class="{active: step === 0}" @click="moveToStep(0)">
              <span>Currency & Beneficiary</span>
            </li>
            <li class="fixed-navigation-item" :class="{active: step === 1}" @click="moveToStep(1)">
              <span>Review</span>
            </li>
          </ol>
        </div>
        <div class="modal-scrollable-wrapper">
          <div class="modal-content" v-show="step === 0">
            <form action="/payment/create">
              <div class="modal-content-section">
                <h3 class="modal-content-title">
                  Currency & Beneficiary
                </h3>

                <div class="modal-content-inner">
                  <div class="grid">
                    <div class="col-6">
                      <form-group label="Wallet" :error="errors.wallet_id">
                        <multiselect
                          v-model="form.balance"
                          :allow-empty="false"
                          :show-labels="false"
                          :options="balancesList"
                          group-values="items"
                          group-label="title"
                          :class="{'valid' : form.balance}"
                          @close="validate(['wallet_id', 'files'])"
                          label="title"
                        >
                          <template #caret >
                            <div>
                              <div
                                  v-if="balancesAcceptedHidden.length"
                                  v-tooltip="{content: `Switch to ${isEyeOpened ? 'hidden' : 'visible'} wallets`}"
                                  class="multiselect-addon multiselect-addon-eye"
                                  :class="{hidden: isEyeOpened}"
                                  @mousedown.prevent="isEyeOpened = !isEyeOpened"
                              />
                              <div class="multiselect__select" />
                            </div>
                          </template>
                          <template #option="{ option }">
                            <div v-if="option.$isLabel" content="option__currency-label">
                              <img class="option__currency-label-img" :src="require(`@/assets/images/currency_flags/${option.$groupLabel}.svg`)" alt="">
                              <span class="option__currency-label-text">{{ option.$groupLabel }}</span>
                            </div>
                            <div v-else class="option__currency">
                              <span class="option__currency-left notranslate">
                                {{ option.title }}
                              </span>
                              <span class="option__currency-right">
                                Balance: {{ option.amount | numeral('0,0.00') }} {{ option.currency.id }}
                              </span>
                            </div>
                          </template>
                          <template #singleLabel="{ option }">
                            <div class="option__currency">
                              <span class="option__currency-left">
                                {{ option.title }}
                              </span>
                            </div>
                          </template>
                        </multiselect>
                        <div class="form-wallet-balance" v-if="form.balance">
                          Balance: <balance-label :amount="form.balance.amount" :currency="form.balance.currency.id" />
                        </div>
                      </form-group>
                    </div>
                    <div class="col-6">
                      <form-group :error="errors.beneficiary_id" label="Beneficiary">
                        <multiselect
                            label="name"
                            @open="isBeneficiariesOpen = true"
                            @close="isBeneficiariesOpen = false; validate(['beneficiary_id', 'files'])"
                            :class="{'valid': form.beneficiary, 'notranslate': true}"
                            v-model="form.beneficiary"
                            :options="beneficiaries"
                            :allow-empty="false"
                            :show-labels="false"
                            :show-no-results="false"
                            :show-no-options="false"
                            :internal-search="false"
                            :searchable="!!form.balance"
                            @search-change="changeSearchString"
                        >
                          <template #option="{ option }">
                            <span class="notranslate">{{ option.name }}</span>
                          </template>
                          <template #afterList>
                            <infinite-loading :identifier="beneficiariesIdentifier" @infinite="beneficiaryFindMore" >
                              <template #no-more>No more results</template>
                              <template #no-results>Beneficiaries list is empty</template>
                            </infinite-loading>
                          </template>
                        </multiselect>
                      </form-group>
                    </div>
                    <div class="col-6">
                      <form-group :error="errors.amount" label="Amount">
                        <amount-input
                            v-model="form.amount"
                            @change.native="validate(['amount', 'files'])"
                            :class="{'valid': form.amount}"
                            name="amount"
                        />
                      </form-group>
                    </div>
                    <div class="col-6">
                      <form-group :error="errors.reference" label="Reference">
                        <input @change="validate(['reference', 'files'])" v-model="form.reference" :class="{'valid': form.reference}" type="text" name="reference" class="form-control">
                      </form-group>
                    </div>
                  </div>
                  <div class="grid" v-if="form.files.length > 0">
                    <div class="col-6" v-for="(file, index) in form.files" :key="index">
                      <form-group label="File" :error="getFileError(index) || errors.files">
                        <span class="file-remove" @click="removeFile(index)">Close X</span>
                        <file-uploader
                          v-model="form.files[index]"
                          :options="fileTypes"
                          @change:file="validate([`files[${index}][name]`, `files[${index}][type]`, 'files'])"
                          @change:multiselect="validate([`files[${index}][type]`, `files[${index}][name]`, 'files'])"
                          @change:busy="changeFileUploaderState"
                        />
                      </form-group>
                    </div>
                  </div>
                  <div class="grid">
                    <div class="col-6">
                      <span class="add-button" style="top: 8px;" @click="addFile">
                        <span class="add-icon">+</span>
                        <span class="add-button-inner">Add file</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="modal-content-bottom-panel">
                  <div class="pull-left">
                    <div class="panel-additional-info for-create-form">
                      <div class="panel-block" v-if="tax && tax.fee">
                        <span class="panel-block-label">Fee</span>
                        <span class="panel-block-value">
                          {{ tax.fee | numeral('0,0.00') }} {{ tax.currency_id }}
                        </span>
                      </div>
                      <div class="panel-block" v-if="tax && tax.fee">
                        <span class="panel-block-label">Total</span>
                        <span class="panel-block-value">
                          {{ tax.total | numeral('0,0.00') }} {{ form.balance.currency.id }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button type="button" @click="moveToStep(1)" :disabled="!isNextEnabled" class="modal-content-bottom-panel-copy next-form-button available" data-dismiss="modal" aria-label="Close">
                    <span>Next</span>
                    <icon name="ic_next" />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-content" v-if="step === 1">
            <div class="modal-content-section payment">
              <h3 class="modal-content-title">
                Receipt
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Amount
                    </p>
                    <p class="modal-inner-copy notranslate">
                      <flag class="icon-flag" :name="form.balance.currency.id" />
                      {{ form.amount | numeral('0,0.00') }} {{ form.balance.currency.id }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Fee
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ tax.fee | numeral('0,0.00') }} {{ form.balance.currency.id }}
                    </p>
                  </div>
                  <div class="col-4" v-if="tax">
                    <p class="modal-inner-title">
                      Total
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ tax.total | numeral('0,0.00') }} {{ form.balance.currency.id }}
                    </p>
                  </div>
                </div>
                <div class="delimiter"/>
                <div class="grid">
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Payment date
                    </p>
                    <p class="modal-inner-copy icon-calendar">
                      {{ new Date() | moment('dddd, MMMM D') }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Reference
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.reference }}
                    </p>
                  </div>
                  <div class="col-4" />
                </div>
              </div>

              <h3 class="modal-content-title">
                Beneficiary
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Name
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.bank_account_holder_name }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Entity type
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.beneficiary_entity_type }}
                    </p>
                  </div>
                  <div class="col-4" v-if="form.beneficiary.address">
                    <p class="modal-inner-title">
                      Address
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.address.house }} {{ form.beneficiary.address.street }}
                    </p>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-4" v-if="form.beneficiary.address">
                    <p class="modal-inner-title">
                      City
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.address.city }}
                    </p>
                  </div>
                  <div class="col-4" v-if="form.beneficiary.address && form.beneficiary.address.country">
                    <p class="modal-inner-title">
                      Country
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.address.country.label }}
                    </p>
                  </div>
                  <div class="col-4" v-if="form.beneficiary.address">
                    <p class="modal-inner-title">
                      Postal Code
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.address.postal_code }}
                    </p>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-4">
                    <p class="modal-inner-title notranslate">
                      {{ labelForBic }}
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.bic_swift }}
                    </p>
                  </div>
                  <div class="col-8">
                    <p class="modal-inner-title notranslate">
                      {{ labelForIban }}
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.beneficiary.iban }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="modal-content-bottom-panel">
                <div class="pull-left" v-if="permissions.canPaymentCreate && signAndCreateShow">
                  <checkbox v-model="signAndCreate">I want to sign this payment</checkbox>
                </div>
                <div class="pull-right">
                  <button type="submit" :disabled="submitDisabled" @click="submit" class="default-button" v-if="canCreatePayment">
                    {{ signAndCreate ? 'Create and sign' : 'Create' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/libs/Icon'
import Flag from '@/libs/Flag'
import groupBy from 'lodash/groupBy'
import Multiselect from 'vue-multiselect'
import FormGroup from '../../libs/forms/FormGroup'
import FileUploader from '../../libs/FileUploader'
import { mapState, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import debounce from 'lodash/debounce'
import ConfirmModal from "./ConfirmModal";
import Checkbox from "../../libs/Checkbox";
import AmountInput from '@/components/modals/AmountInput'
import BalanceLabel from "@/libs/BalanceLabel";
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'

export default {
  name: 'PaymentCreateModal',
  components: {
    AmountInput,
    Checkbox,
    Multiselect,
    FormGroup,
    FileUploader,
    InfiniteLoading,
    Icon,
    Flag,
    BalanceLabel
  },
  props: {
    walletId: {
      type: Number,
      default: null
    },
    beneficiaryId: {
      type: Number,
      default: null
    },
    reference: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      /** Form data fields */
      form: {
        beneficiary: null,
        balance: null,
        amount: null,
        reference: null,
        files: [],
        status: null
      },

      step: 0,
      errors: {},

      signAndCreate: null,
      signAndCreateShow: null,

      fileTypes: [
        {
          id: 'invoice',
          label: 'Invoice'
        },
        {
          id: 'agreement',
          label: 'Agreement'
        }
      ],

      /** Turn button status **/
      submitDisabled: false,
      loadFilesInProcess: false,

      /** Other props */
      wallet: null,

      // List of searched beneficiaries
      beneficiaries: [],
      beneficiariesIdentifier: +new Date(),
      beneficiariesPage: 1,
      beneficiariesQuery: undefined,
      isBeneficiariesOpen: false,

      isEyeOpened: true,
    }
  },
  mounted() {
    this.getDefaultValueCreateAndSign()
  },
  asyncComputed: {
    tax: {
      get () {
        return this.$sdk.payment.getPaymentFee({
          wallet_id: this.form.balance ? this.form.balance.id : null,
          amount: this.form.amount,
          beneficiary_id: this.form.beneficiary ? this.form.beneficiary.id : null
        }).then(response => response.data)
      },
      shouldUpdate () {
        return this.form.balance
            && this.form.amount
            && this.form.beneficiary
      },
      default: {
        currency: null,
        fee: null,
        total: null
      }
    }
  },
  computed: {
    ...mapGetters({
      balances: 'getActiveBalances',
      isMfaEnabled: 'isMfaEnabled',
      permissions: 'permissions'
    }),
    ...mapState({
      user: state => state.user.data,
    }),

    /**
     * List of balances filtered by permissions
     * @returns {*}
     */
    balancesAccepted () {
      return this.balances.filter(i => i.permissions.canPay)
    },

    /**
     * List of balances group by currency id
     * @returns {*[]}
     */
    balancesList () {
      const data = []
      const list = this.balancesAccepted.filter(i => this.isEyeOpened ? i.isVisible : !i.isVisible)
      const items = groupBy(list, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },

    /**
     * List of hidden accepted balances
     * @returns {*}
     */
    balancesAcceptedHidden () {
      return this.balancesAccepted.filter(i => !i.isVisible)
    },

    canCreatePayment () {
      return this.permissions.canPaymentCreate || this.permissions.canPaymentDraftCreate
    },

    /**
     * Collect data to sending
     */
    formData () {
      const data = {
        amount: this.form.amount,
        beneficiary_id: this.form.beneficiary ? this.form.beneficiary.id : '',
        wallet_id: this.form.balance ? this.form.balance.id : '',
        reference: this.form.reference,
        status: this.signAndCreate ? 'new' : 'draft',
        files: []
      }

      for (const i in this.form.files) {
        data.files[i] = {
          type: this.form.files[i].type.id || '',
          name: this.form.files[i].data.name || ''
        }
      }

      return data
    },
    // isAllButtonEnabled () {
    //   return this.form.beneficiary && this.form.balance && this.maxAmount
    // },

    /**
     * Check is it local payment in GBP and bank country United Kingdom
     * @return {boolean}
     */
    isLocalPayment () {
      return this.form.beneficiary?.bank_details_type === 'LOCAL'
    },

    labelForIban () {
      return this.isLocalPayment ? 'Account number' : 'IBAN'
    },

    labelForBic () {
      return this.isLocalPayment ? 'Sort Code' : 'BIC/SWIFT'
    },

    /**
     * Check if errors array contains files errors
     *
     * @returns {boolean}
     */
    hasFilesErrors () {
      let hasErrors = false

      for (let index in this.form.files) {
        if (
            has(this.errors, `files[${index}][name]`) ||
            has(this.errors, `files[${index}][type]`)
        ) {
          hasErrors = true
        }
      }

      return hasErrors
    },

    isNextEnabled () {
      return this.form.amount
          && this.form.balance
          && this.form.reference
          && this.form.beneficiary
          && !this.errors['reference']
          && !this.errors['wallet_id']
          && !this.errors['beneficiary_id']
          && !this.errors['amount']
          && !this.errors['files']
          && !this.hasFilesErrors
          && !this.loadFilesInProcess
    }
  },
  watch: {
    'form.balance': function (newVal, oldVal) {
      // If we change currency
      if (newVal && oldVal && (oldVal.currency.id !== newVal.currency.id)) {
        this.form.beneficiary = null
      }

      this.beneficiariesReset()
      this.beneficiaryFindMore({
        loaded: () => {},
        complete: () => {}
      })
    },
    'isBeneficiariesOpen': function (newVal) {
      (newVal && this.form.balance) && this.beneficiariesIdentifier++
    }
  },
  async created () {
    // Preselect wallet
    if (this.walletId) {
      let wallet = this.balances.find(item => {
        return item.id === this.walletId
      })

      if (wallet) {
        this.form.balance = wallet
      }
    }

    // Preselect balance
    if (this.beneficiaryId) {
      const beneficiary = await this.$sdk.beneficiary
          .find(this.beneficiaryId)
          .then(response => response.data)

      if (beneficiary) {
        // If is not preselect wallet
        if (!this.form.balance) {
          const list = this.balancesList.filter(item => {
            return item.title === beneficiary.currency_id
          })

          if (Array.isArray(list) && list.length > 0) {
            this.form.balance = list[0].items[0]
          }
        }

        this.form.beneficiary = beneficiary
      }
    }

    if (this.reference.length > 0) {
      this.form.reference = this.reference
    }

    if (this.amount.length > 0) {
      this.form.amount = this.amount
    }
  },
  methods: {
    getDefaultValueCreateAndSign () {
      if (this.permissions.canPaymentCreate) {
        this.signAndCreateShow = true
        this.signAndCreate = true
      } else {
        this.signAndCreateShow = false
        this.signAndCreate = false
      }
    },

    getFileError: function (index) {
      // Map error from fileUploader
      if (this.form.files[index] && this.form.files[index].data.error) {
        return this.form.files[index].data.error
      }

      return this.errors[`files[${index}][name]`]
          || this.errors[`files[${index}][type]`]
    },

    /**
     * Add new file field
     */
    addFile: function () {
      this.form.files.push({
        type: {
          id: ''
        },
        data: {
          name: ''
        }
      })
    },

    /**
     * Remove file field
     */
    removeFile: function (index) {
      this.$delete(this.form.files, index)

      let keys = [`files[${index}][name]`, `files[${index}][type]`, 'files']
      for (let key of keys) {
        if (has(this.errors, key)) {
          this.$delete(this.errors, key)
        }
      }
    },

    /**
     * Form validating
     *
     * @param {String|Array} attributes
     * @returns {Promise<AxiosResponse<T>>}
     */
    validate (attributes) {
      const data = cloneDeep(this.formData)
      data.status = 'draft'

      return this.$sdk.payment.create(data, 1).then(async (response) => {
        if (Array.isArray(attributes) && attributes.length) {
          for (let attribute of attributes) {
            if (has(response.data, attribute)) {
              this.$set(this.errors, attribute, response.data[attribute])
            } else {
              this.$delete(this.errors, attribute)
            }
          }
        } else {
          this.errors = []

          if (Object.keys(response.data).length > 0) {
            /** Set all errors **/
            for (const attribute in response.data) {
              this.$set(this.errors, attribute, response.data[attribute])
            }
          }

          await this.validateNew()
        }

        /** Show file input if returned files error **/
        if (
            has(this.errors, 'files') &&
            !this.form.files.length
        ) {
          this.addFile()
        }

        return response
      })
    },

    /**
     * Validate formData with status new to detect could create the payment or not
     * If balance is low I need to change checkbox to false value and hide the checkbox
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    validateNew() {
      let formData = cloneDeep(this.formData)
      if (this.permissions.canPaymentSign) {
        formData.status = 'new'
      }

      return this.$sdk.payment.create(formData, 1).then(({ data }) => {
        this.getDefaultValueCreateAndSign()

        if (!Array.isArray(data) && has(data, 'amount')) {
          this.signAndCreate = false
          this.signAndCreateShow = false
        }
      })
    },

    /**
     * Form submitting
     */
    submit () {
      this.submitDisabled = true

      let finalFunction = () => this.submitDisabled = false
      let catchFunction = () => this.$notice.error('Something was wrong. Please reload page and try again')

      let successFunction = (response) => {
        if (Object.keys(response.data).length > 0) {
          this.errors = []

          for (const attribute in response.data) {
            this.$set(this.errors, attribute, response.data[attribute])
          }
        } else {
          this.close()
          this.$notice.success('Payment was created successfully')
        }

        return response
      }

      const data = cloneDeep(this.formData)

      if (this.signAndCreate) {
        this.$modal.show(ConfirmModal, {
          beforeClose: () => {
            this.submitDisabled = false
          },
          submit: ({ code }) => {
            data.code = code
            return this.$sdk.payment.create(data, 0)
                .then(successFunction)
                .catch(catchFunction)
                .finally(finalFunction)
          }
        })
      } else {
        this.$sdk.payment.create(data, 0)
            .then(successFunction)
            .catch(catchFunction)
            .finally(finalFunction)
      }
    },

    moveToStep (step) {
      this.validate().then((response) => {
        const errors = response.data
        delete errors.status

        if (Object.keys(errors).length === 0) {
          this.step = step
        }

        return response
      })
    },

    /**
     * Close modal window
     */
    close: function () {
      this.$emit('close')
    },

    changeFileUploaderState (value) {
      this.loadFilesInProcess = value
    },

    /**
     * Change beneficiary search string
     */
    changeSearchString: debounce(function (query) {
      this.beneficiariesQuery = query.length ? query : undefined
      this.beneficiariesReset()
    }, 200),

    beneficiariesReset () {
      this.beneficiariesPage = 1
      this.beneficiaries = []

      this.beneficiariesIdentifier++
    },

    /**
     * Ajax search of beneficiary
     */
    beneficiaryFindMore ($state) {
      return this.$sdk.beneficiary.list({
        page: this.beneficiariesPage,
        name: this.beneficiariesQuery,
        currency_id: this.form.balance ? this.form.balance.currency.id : null,
        sort: 'name',
        expand: ['address', 'address.country'].join(',')
      }).then(({ data, headers }) => {
        let maximumPage = parseInt(headers['x-pagination-page-count'])
        if (maximumPage === 0) {
          $state.complete()
          return
        }

        $state.loaded()
        if (this.beneficiariesPage < maximumPage + 1) {
          if (Array.isArray(data) && data.length > 0) {
            this.beneficiaries.push(...data)
            this.beneficiariesPage++
          }
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style lang="less">
.option__currency {
  &-left,
  &-right {
    display: block;
    white-space: nowrap;
  }

  &-right {
    margin-top: 8px;
    font-size: 12px;
    //border-top: 1px solid rgba(0, 0, 0, .1);
    //padding-top: 8px;
    margin-right: 10px;
  }

  &-left {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:after {
    content: ' ';
    display: block;
    clear: both;
  }

  &-label {
    &-img {
      vertical-align: middle;
      margin-right: 10px;
      line-height: 20px;
      position: relative;
      top: -1px;
    }
    &-text {
      vertical-align: middle;
      line-height: 20px;
    }
  }
}

@media all and (max-width: 468px) {
  .option__currency {
    padding: 10px 0;

    &-left {
      line-height: 1em;
    }
    &-right {
      font-size: 14px;
      line-height: 1em;

      padding-top: 10px;
    }

    .multiselect__option > & {
      padding: 0;
    }
  }
}

.fee-block {
  position: absolute;
  right: 12px;
  top: 28px;
  color: #19191C;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 2px;
  background: #fff;
}

.file-remove {
  color: #EA4C17;
  position: absolute;
  font-size: 12px;
  font-family: Graphik, 'helvetica neue', Arial, sans-serif;
  z-index: 1;

  right: 0;
  top: 0;
  cursor: pointer;
}

.send-money-modal {
  white-space: nowrap;
}

.form-wallet-balance {
  font-size: 13px;
  margin-top: 7px;
}

.notice {
  padding: 10px 22px;
  background-color: gray;
  border-radius: 3px;

  &-error {
    background-color: #EA4C17;
    color: #FFF;
  }
}
</style>
