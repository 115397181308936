<template>
  <img :src="require(`@/assets/images/currency_flags/${name}.svg`)" :alt="alt">
</template>

<script>
export default {
  name: 'Flag',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    alt () {
      return 'Currency flag ' + this.name
    }
  }
}
</script>
