<template>
  <span class="balance-label" :class="labelClass">
    {{ amount | numeral('0,0.00') }} {{ currency }}
  </span>
</template>

<script>
export default {
  name: 'BalanceLabel',
  props: {
    amount: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    target: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelClass () {
      return Number(this.amount) > 0 || this.target
          ? 'yellow-colored'
          : 'red-colored'
    }
  }
}
</script>

<style lang="less">
.balance-label {
  font-weight: 500;
}
</style>

